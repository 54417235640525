<template>
    <div>
        <EChartCustom
            high="800"
            :series="state.series"
            :labely="state.labely"
            :labelx="state.labelx"
            :grid="state.grid"
            :tooltip="state.tooltip"
            :loading="state.loading"/>

        <div class="legend-container">
            <span class="legend-title">Fullname:</span>
            <div class="legend-items-wrapper">
                <div class="legend-items-container" ref="legendContainer">
                    <div
                        v-for="(item, index) in state.salesmans"
                        :key="index"
                        :class="[`legend-item`, {
                            'inactive': !item.isActive,
                        }]"
                        @click="item.isActive = !item.isActive; setUpInit()">
                        <div
                            class="legend-color"
                            :style="{ backgroundColor: item.color }"/>
                        <span>{{ item.salesman }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import EChartCustom from '@/components/charts/EChartCustom'
import _ from 'lodash'

// reference Hierarchical Data
// https://github.com/apache/echarts/issues/13839

export default defineComponent({
    components: {
        EChartCustom,
    },
    props: {
        endpoint: {
            default: () => '',
            type: String,
        },
        params: {
            default: () => ({}),
            type: Object,
        },
        isHoverLabel: {
            default: false,
            type: Boolean,
        },
    },
    setup(props) {
        const state = reactive({
            loading: false,
            originals: [],
            salesmans: [],
            grid: {
                left: 253,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: (params) => {
                    return params.reduce((tooltip, param) => {
                        return `${tooltip}${param.marker}${param.name} (${param.value.toFixed(2)})<br>`;
                    }, '')
                },
            },
            labely: [
                {
                    name: 'Full Name > Nama Distributor',
                    nameLocation: 'middle',
                    nameGap: 240,
                    type: "category",
                    inverse: true,
                    axisTick: { show: false },
                    data: [],
                },
                {
                    nameLocation: 'start',
                    position: "left",
                    data: [],
                    nameTextStyle: {
                        fontWeight: 'bold',
                    },
                    axisLabel: {
                        inside: true,
                    },
                    offset: 235,
                    inverse: true,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        length: 235,
                        inside: true,
                        lineStyle: {
                            color: '#ccc',
                        },
                        interval: function (index, value) {
                            return value !== '';
                        },
                    },
                },
            ],
            labelx: [{
                name: 'Record Count',
                nameLocation: 'middle',
                nameGap: 30,
                type: 'value',
            }],
            series: [],
        })

        const fetchDataChartList = () => {
            state.loading = true

            apiClient
                .get(props.endpoint, {params: {...props.params}})
                .then(({ data }) => {
                    data.forEach((item) => {
                        item.isActive = true
                        item.color = '#' + _.sampleSize('0123456789ABCDEF', 6).join('')
                    })

                    state.originals = data
                    state.salesmans = _.uniqBy(state.originals, 'salesman_id')
                })
                .finally(() => {
                    setUpInit()
                    state.loading = false
                })
        }

        const setUpInit = () => {
            let payloads = state.originals.filter(data => 
                !_.includes(
                    _.map(state.salesmans.filter(salesman => !salesman.isActive), 'salesman_id'), 
                    data.salesman_id,
                ),
            )

            // let uniqueDistributors = new Set();
            // let payloads = data.map(item => {
            // let uniqueDistributor = uniqueDistributors.has(item.distributor) ? '' : item.distributor
            // uniqueDistributors.add(item.distributor)
            //     return { ...item, uniqueDistributor }
            // })

            state.labely[0].data = payloads.map(item => item.salesman.length > 12 ? item.salesman.substring(0, 12) + '...' : item.salesman)
            state.labely[1].data = payloads.map(item => item.distributor.length > 12 ? item.distributor.substring(0, 12) + '...' : item.distributor)
            // state.labely[1].data = payloads.map(item => item.uniqueDistributor.length > 12 ? item.uniqueDistributor.substring(0, 12) + '...' : item.uniqueDistributor)

            let series = {
                type: "bar",
                data: payloads.map(item => item.visits),
                itemStyle: {
                    color: (params) => payloads[params.dataIndex].color,
                },
                label: {},
            }

            if (props.isHoverLabel) {
                series.label = {
                    show: true,
                    position: 'insideRight',
                    formatter: (params) => params.value !== 0 ? `${params.value.toFixed(2)}` : '',
                }
            }

            state.series = [series]
        }

        onMounted(() => {
            fetchDataChartList()
        })

        return {
            state,
            setUpInit,
        }
    },

})
</script>


<style lang="scss" scoped>
.legend-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
}
.legend-title {
  margin-right: 10px;
  font-weight: bold;
  flex-shrink: 0;
}
.legend-items-wrapper {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}
.legend-items-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.legend-items-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}
.legend-color {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.legend-item.inactive {
  opacity: 0.5;
}
.legend-item.inactive .legend-color {
  background-color: #ccc;
}
.legend-items-wrapper:hover .legend-items-container {
  overflow-x: auto;
}
</style>